/*****************  Dotted link focus outline  ************/
/**********************************************************/
/* /core/libraries/slickSlider/assets/1.4.1/slick.css */
.slick-list:focus {
	outline: dotted;
}

/*******  Use relative than absolute unit values  *********/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.typed {
	font: normal 1.875em/3.125em "Journal", Georgia, Times, serif;
}

/* /core/modules/rates/assets/stylesheets/all.css */
.rates-collection.bulkedit .category-header th {
	font-size: 0.9375rem;
}

.rates-collection.bulkedit #errorRow td div {
	font-size: 0.6875rem;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-container .fly-out-menu-button .glyph-keyboard_arrow_down {
	font-size: 1.25rem;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend {
	font-size: 1.06rem;
}

.ui-form-note-extra {
	font-size: 0.6875rem;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	font-size: 0.6875rem;
}

/* /core/website/modules/alerts/css/screen.css */
#alerts_module.homepage_contentbox.list_alerts .alerts a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts a:visited {
	font-size: 0.8125rem;
}

#alerts_module.homepage_contentbox.list_alerts .alerts a:hover,
#alerts_module.homepage_contentbox.list_alerts .alerts a.selected,
#alerts_module.homepage_contentbox.list_alerts .alerts a.selected:hover {
	font-size: 0.8125rem;
}

#alerts_module.homepage_contentbox.list_alerts .date {
	font-size: 0.6875rem;
}

#alerts_module.homepage_contentbox.list_alerts .list_item .metadata1.title a {
	font-size: 0.6875rem;
}

#alerts_module.homepage_contentbox.list_alerts .footer a {
	font-size: 0.625rem;
}

/* /core/website/modules/eCommerceProducts/css/slider.css */
.slick-arrow-font {
	font-size: 1.25rem;
}

/* /core/website/modules/feeds/css/screen.css.cfm */
#feeds_module.cms_list .cms_list_item .cms_date {
	font-size: 0.75rem;
}


#feeds_module.cms_list .cms_list_item .cms_metadata2.cms_date {
	font-size: 0.6875rem;
}

#feeds_module.homepage_contentbox .item .date-published {
	font-size: 0.6875rem;
}

/* /core/website/modules/forums/css/responsive.css */
#forums_module h2 {
	font-size: 1.25rem;
}

#forums_module #forum-search #forum-search-container input,
#forums_module #forum-search #forum-search-container label {
	font-size: 0.8125rem;
}

#forums_module #forum-search #forum-search-container .advanced a {
	font-size: 0.8125rem;
}

/* /core/website/modules/forums/css/screen.css.cfm */
#forums_module h2 {
	font-size: 1.124rem;
}

#forums_module #posts .post_quote {
	font-size: 0.75rem;
}

#forums_module #posts .post_quote_title {
	font-size: 0.75rem;
}

#forums_module #posts .post_quote {
	font-size: 0.75rem;
}

.edited-by {
	font-size: 0.6875rem;
}

#forums_module .action-popup {
	font-size: 0.6875rem;
}

#forums_module .close-modal-button {
	font-size: 1rem;
}

/* /ore/website/modules/jobs/css/screen.css */
#jobs_module.cms_list .cms_list_item .cms_date h3 {
	font-size: 0.6875rem;
}

#jobs_module div.application-for-employment div.required_field_information {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment .all_fields_required_note {
	font-size: 0.6875rem;
}

#jobs_module div.application-for-employment form div.form-field label {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field label em,
#jobs_module div.application-for-employment .required {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span.text input {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span.textarea textarea {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span label {
	font-size: 0.5625rem;
}

#jobs_module div.application-for-employment form div.form-field span.checkbox label {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field select {
	font-size: 0.75rem;
}

#jobs_module div.application-for-employment form div.form-field span small {
	font-size: 0.5625rem;
}

/* /core/website/modules/newsletters/css/screen.css */
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:link,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:active,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:visited {
	font-size: 0.75rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a:hover,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected,
#newsletters_module.homepage_contentbox.displaymode_list_with_issues .newsletters a.selected:hover {
	font-size: 0.75rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .list_item .metadata2.date {
	font-size: 0.6875rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .list_item .metadata1.title a {
	font-size: 0.6875rem;
}

#newsletters_module.homepage_contentbox.displaymode_list_with_issues .footer a {
	font-size: 0.6875rem;
}

/* /core/website/modules/photoalbums/css/screen.css */
#photoalbums_module.cms_entity .slideshow .post {
	font-size: 0.6875rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide {
	font-size: 0rem;
}

#photoalbums_module.cms_entity .slideshow-info {
	font-size: 0.625rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	font-size: 0.6875rem;
}

/* core/website/modules/submissionforms/css/print.css*/
.module-forms.confirmation .step-title {
	font-size: 0.875rem;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.cms_form .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms.confirmation .step-title {
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox .cms_hint,
#submissionforms_module.homepage_contentbox .cms_date_hint {
	font-size: 0.6875rem;
}

#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.6875rem;
}

.module-forms .ui-form-field-payment-information,
.module-forms .ui-data-addresses h3 {
	font-size: 0.875rem;
}

/* core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-listview {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-listview .mm-divider {
	font-size: 0.625rem;
	line-height: 1.5625rem;
}

em.mm-counter {
	font-size: 0.875rem;
	line-height: 1.25rem;
}

.mm-search input {
	font-size: 0.875rem;
	line-height: 1.625rem;
}

.mm-noresultsmsg {
	font-size: 1.3125rem;
}

.mm-indexer {
	font-size: 0.75rem;
}

/***  Foreground and background colors contrast  *****/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
p.error {
	background-color: #ee0000;
	color: #fff;
}

/* /core/libraries/tablesaw/assets/2.0.3/tablesaw.bare.css */
.tablesaw-enhanced .tablesaw-bar .btn:hover,
.tablesaw-enhanced .tablesaw-bar .btn:focus {
	color: #1465a3;
	background-color: #fff;
}

/* /core/modules/files/assets/stylesheets/all.css */
.controller-manage-categories form fieldset .legend {
	background: #585656;
	color: #ffffff;
}

/* /core/public/shared/assets/css/responsive.css */
.fly-out-menu-list-container ul.fly-out-menu-list li a:active,
.fly-out-menu-list-container ul.fly-out-menu-list li.selected a {
	background: #11406f !important;
	color: #ffffff !important;
}

/* /core/public/shared/assets/css/screen.css */
.search-facets .search-facet .price-range-button a {
	background-color: #4f4f4f;
	color: #ffffff;
}

/* /core/public/shared/assets/css/uitags.css */
.ui-form-legend h3 .ui-form-legend-step {
	background: #ffffff;
	color: #545454;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-basic-view .fc-day-top .fc-week-number {
	background-color: #5e5e5e;
	color: #808080;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list div.events_module_calendar .fc-view-container .fc-event {
	background-color: #9c9c9c;
	color: #000000;
}

/* core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-menu .mm-search input {
	background: rgba(0, 0, 0, 0.05);
	color: rgba(117, 117, 117, 0.7);
}

.mm-menu.mm-theme-dark .mm-search input {
	background: rgba(117, 117, 117, 0.3);
	color: rgba(255, 255, 255, 0.8);
}

.mm-menu.mm-theme-white .mm-search input {
	background: rgba(0, 0, 0, 0.05);
	color: rgba(117, 117, 117, 0.6);
}

.mm-menu.mm-theme-black .mm-search input {
	background: rgba(117, 117, 117, 0.3);
	color: rgba(255, 255, 255, 0.6);
}

/**********  Removing the underline from links  ***********/
/**********************************************************/
/* /core/libraries/jSignaturePad/assets/jquery.signaturepad.css */
.sigNav a.current,
.sigNav a.current:link,
.sigNav a.current:visited {
	text-decoration: underline;
}


/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-list-item-title a {
	text-decoration: underline;
}

/* core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-listview a,
.mm-listview a:hover,
.mm-navbar a,
.mm-navbar a:hover {
	text-decoration: underline;
}

.mm-indexer a {
	text-decoration: underline;
}

/*  All information with color is available without color  */
/**********************************************************/
/* core/modules/rates/assets/stylesheets/all.css */
.rates-collection.bulkedit .ui-state-highlight td {
	background-color: #fffafa !important;
	font-weight: 500;
}

.rates-collection.bulkedit .form-field-error {
	color: #ff0000;
	font-weight: 500;
}

/* core/public/shared/assets/css/responsive.css */
.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #999999;
	font-weight: 500;
}

/* core/public/shared/assets/css/uitags.css */
.collapsed .ui-form-legend {
	background-color: #8a8a8a;
	font-weight: 500;
}


.ui-form-field-subtitle .ui-form-label label,
.ui-form-field-subtitle .ui-form-label span {
	color: #000000;
	font-weight: 500;
}

/* /core/public/shared/assets/js/jquery/fullcalendar/3.2.0/fullcalendar.css */
.fc-unthemed .fc-popover {
	background-color: #fff;
	font-weight: 500;
}

.fc-unthemed .fc-popover .fc-header .fc-close {
	color: #666;
	font-weight: 500;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	font-weight: 500;
}

.fc-unthemed .fc-list-empty { /* theme will provide own background */
	background-color: #eee;
	font-weight: 500;
}

/* core/website/modules/accounts/assets/css/screen.css */
#account_orderhistory .order_section,
#account_addresses .address_section.current_cart {
	background-color: #f0f0f0;
	font-weight: 500;
}

/* core/website/modules/eCommerceProducts/css/responsive.css */
.products_module .item-sale-price,
#products_module.responsive .item-sale-price {
	color: #ff0000 !important;
	font-weight: 500;
}

#products_module.responsive .add_to_cart_form .quantity_row #quantity_available_info.no_available_quantity {
	color: #ff0000;
	font-weight: 500;
}


#products_module.responsive .add_to_cart_form .quantity_row #quantity_available_info.available_quantity {
	color: #567e3a;
	font-weight: 500;
}

#products_module.responsive .availability_box .available {
	color: #42b4e6;
	font-weight: 500;
}

#products_module.responsive .availability_box .unavailable {
	color: #cc0000;
	font-weight: 500;
}

#products_module.responsive.cms_entity .product-details-tabs {
	font-weight: 500;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
	background-color: #d6d6d6;
	font-weight: 500;
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item:hover {
	background-color: #ffffff;
	font-weight: 500;
}

/* /core/website/modules/events/css/screen.css */
#events_module.cms_list .cms_button:hover {
	background-color: #dddddd;
	font-weight: 500;
}


#events_module.cms_list .cms_oddRow {
	background-color: #ffffff;
	font-weight: 500;
}

#events_module.cms_form .cms_button:hover {
	background-color: #dddddd;
	font-weight: 500;
}

#events_module.cms_list .fc-list-item:hover td .fc-event-dot[style="background-color:#FFFFFF"] {
	background-color: #f5f5f5 !important;
	font-weight: 500;
}

.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	color: #333333;
	background-color: #e6e6e6 !important;
	font-weight: 500;
}

.full_calendar_view .fc-state-active {
	font-weight: 500;
	background-color: #cccccc !important;
}

#events_module.homepage_contentbox.conference-tabs .conference-heading.tabbed.current {
	background-color: #ffffff;
	color: #000000;
	font-weight: 500;
}

#events_module.homepage_contentbox.conference-tabs .tab-heading-text {
	color: inherit;
	font-weight: 500;
}

#events_module.homepage_contentbox.conference-tabs .schedule-wrapper {
	background-color: #ffffff;
	font-weight: 500;
}

/* /core/website/modules/forums/css/screen.css.cfm */
#forums_module .search-result {
	color: #ff0000;
	font-weight: 500;
}

#forums_module .pagination .page-num.selected {
	color: #000000;
	font-weight: 500;
}

/* /core/website/modules/submissionforms/css/screen.css */
#submissionforms_module.cms_form .cms_steps_wrapper .active,
#submissionforms_module.cms_form .cms_steps_wrapper .last_active {
	background-color: #d1d0ce;
	font-weight: 500;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.container-button:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.container-button:hover {
	background-color: hsla(90, 20%, 65%, 0.15);
	font-weight: 500;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.add:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.add:hover {
	color: #348300;
	font-weight: 500;
}

#submissionforms_module.cms_form .container-buttons-wrapper span.remove:hover,
#submissionforms_module.homepage_contentbox .container-buttons-wrapper span.remove:hover {
	color: #d3121a;
	font-weight: 500;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	font-weight: 500;
}

#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover {
	background-color: #dddddd;
	font-weight: 500;
}

/* core/libraries/jquery.mmenu/assets/5.6.5/css/jquery.mmenu.all.css */
.mm-menu .mm-noresultsmsg {
	color: rgba(0, 0, 0, 0.3);
	font-weight: 500;
}

.mm-menu.mm-theme-dark .mm-indexer a,
.mm-menu.mm-theme-dark .mm-noresultsmsg {
	color: rgba(255, 255, 255, 0.4);
	font-weight: 500;
}

.mm-menu.mm-theme-white .mm-indexer a,
.mm-menu.mm-theme-white .mm-noresultsmsg {
	color: rgba(0, 0, 0, 0.3);
	font-weight: 500;
}

.mm-menu.mm-theme-black .mm-indexer a,
.mm-menu.mm-theme-black .mm-noresultsmsg {
	color: rgba(255, 255, 255, 0.4);
	font-weight: 500;
}

.main-content-wrap .select2-offscreen,
.main-content-wrap .select2-offscreen:focus {
	outline: 1px solid #18639d !important;
}

.main-content-wrap .select2-container-multi .select2-choices .select2-search-field input,
.main-content-wrap .select2-container-multi .select2-choices .select2-search-field input:focus,
.main-content-wrap .select2-container-multi .select2-choices .select2-search-field input,
.main-content-wrap .select2-container-multi .select2-choices .select2-search-field input:focus {
	outline: 1px solid #18639d !important;
}
